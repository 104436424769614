<template lang="html">
  <div class="accueil-hortilio">
    <b-row class="d-flex flex-wrap align-items-center justify-content-center mx-50">
      <h1 class="text-primary font-large-3 hortilio-title w-100 text-center" style="">HORTILIO</h1>

      <b-card class="text-center max-w-xl font-medium-3 text-dark">
        <p class="font-weight-bold my-0">
          Une application vivante au service du jardin.
        </p>
        <br>
        <p>
          Gratuite et sans publicité.
        </p>
        <p>
          En toute saison, pour les jardiniers apprentis comme experts.
        </p>
        <p>
          Soucieuse de la biodiversité, elle vous apporte un savoir pratique, directement applicable et enrichi par
          vos retours d'experience.
        </p>
        <b-button
            class="mt-2"
            href="https://hortilio.fr"
            variant="primary"
        >
          Créer un compte sur hortilio.fr
          <feather-icon
              icon="ArrowRightIcon"
              size="16"
          />
        </b-button>
      </b-card>
    </b-row>

    <b-row class="d-flex justify-content-center mb-1">
      <div class="d-flex px-1" style="overflow-y: hidden; overflow-x: auto;">
        <b-card
            v-for="(item, index) in items"
            :key="index" slot="no-body" class="bg-dark text-white m-50"
            style="min-width: 16rem !important; max-width: 16rem !important;"
        >
          <div class="d-flex flex-wrap justify-content-center">
            <div
                class="bg-white rounded-circle d-flex justify-content-center"
                style="height: 150px; width: 300px;"
            >
              <img :alt="ImageDescription(item.picto)" :src="getImage(item.location)" class="fit">
            </div>
            <div class="height-150 text-center mt-1">
              <p class="text-lg font-semibold">{{ item.text }}</p>
            </div>
          </div>
        </b-card>
      </div>
    </b-row>

    <b-row class="d-flex align-items-center justify-content-center mx-50">
      <b-card slot="no-body" class="w-100 mb-3 max-w-xl">
        <b-row class="d-flex flex-wrap">
          <b-col class="d-flex flex-column align-items-center" cols="12">
            <h1 class="text-center order-1 order-lg-0">Disponible sur toutes les plateformes </h1>
            <img class="mb-50" alt="Application page mon jardin" src="@/assets/images/phone/MonJardin.png" style="height: 300px;">
          </b-col>
          <b-col class="w-100 d-flex justify-content-center align-items-center flex-wrap mt-1 max-w-md" cols="12">
            <a @click="$openLinkJaya('https://apps.apple.com/fr/app/hortilio/id1539173291?itsct=apps_box&amp;itscg=30200')">
              <img :class="smallScreen ? 'height-50 py-50' : 'height-100 py-1'" alt="Download on the App Store" src="@/assets/images/app_store_badges/apple-badge.svg"/>
            </a>

            <a @click="$openLinkJaya('https://play.google.com/store/apps/details?id=fr.jaya.hortilio&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1')">
              <img :class="smallScreen ? 'height-50' : 'height-100'" alt="Disponible sur Google Play" src="@/assets/images/app_store_badges/google-play-badge.png"/>
            </a>

            <b-button
                class="mt-1"
                href="https://hortilio.fr"
                variant="primary"
            >
              Créer un compte sur hortilio.fr
              <feather-icon
                  icon="ArrowRightIcon"
                  size="16"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-row>

    <div class="d-flex flex-wrap align-items-center justify-content-center w-100 font-medium-3 mt-1">
      <span class=""> Retrouvez-nous sur : </span>
      <b-button
          class="p-50"
          pill
          variant="flat-dark"
          @click="$openLinkJaya('https://www.facebook.com/hortilio.fr')"
      >
        <feather-icon
            icon="FacebookIcon"
            size="20"
        />
      </b-button>
      <b-button
          class="p-50"
          pill
          variant="flat-dark"
          @click="$openLinkJaya('https://www.instagram.com/hortilio.fr/')"
      >
        <feather-icon
            icon="InstagramIcon"
            size="20"
        />
      </b-button>
    </div>

    <jaya-divider color="transparent"/>

  </div>
</template>

<script>
import { BButton, BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      items: [
        {
          picto: 'transplantoire',
          location: 'tools/garden_trowel',
          text: 'Evaluez la capacité de votre jardin à accueillir la biodiversité, inspirez vous des fiches pratiques et obtenez des conseils d’experts adaptés à votre jardin.'
        },
        {
          picto: 'potager',
          location: 'other/vgarden',
          text: 'Concevez votre potager en prenant en compte les interactions entre espèces et profitez de conseils personnalisés pour son entretien.'
        },
        {
          picto: 'location',
          location: 'other/location',
          text: 'Impliquez-vous dans la végétalisation de votre territoire: consultez les évènements, lieux et manifestations autour de chez vous grâce à une carte et à un calendrier interactifs.'
        },
        {
          picto: 'forum',
          location: 'other/question_bubbles',
          text: 'Participez et soyez acteur d’un savoir collectif en partageant vos expériences avec les autres utilisateurs et en interagissant avec eux sur un forum. '
        },
        {
          picto: 'feuille',
          location: 'plants/Mentha',
          text: 'Retrouvez une multitude d’informations liées aux différentes espèces de plantes présentes dans votre jardin dans notre encyclopédie. '
        }
      ]
    }
  },
  computed: {
    smallScreen() {
      return window.innerHeight < 665 && window.innerWidth < 370
    }
  },
  methods: {
    getImage(imageLocation) {
      let img = ''
      try {
        img = require('@/assets/images/icons/' + imageLocation + '.svg')
      } finally {
        // do nothing
      }
      return img
    },
    ImageDescription(object) {
      return 'Image d\'un pictogramme de ' + object
    }
  }
}
</script>

<style lang="scss">

.accueil-hortilio {
  a {
    color: black;
  }
}
</style>
